.ant-btn {
  &:hover,
  &:focus {
    color: #1cbcbc;
    border-color: #1cbcbc;
  }

  &.ant-btn-link {
    color: #1cbcbc;
  }

  &.ant-btn-primary {
    &:hover,
    &:focus {
      color: #fff;
    }
  }

  &.ant-btn-default.ant-btn-secondary {
    background-color: #fff;
    border-color: #1cbcbc;
    color: #1cbcbc;

    &:hover,
    &:focus {
      background-color: #1cbcbc;
      border-color: #fff;
      color: #fff;
    }
  }
}
