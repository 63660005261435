.auth-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px;

  .wrapper {
    width: 400px;
    margin-top: 36px;
  }

  .title {
    margin-top: 36px;
    margin-bottom: 48px;
  }

  .forgot-password {
    margin-right: 12px;
  }
}

.signup-form {
  width: 400px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  // justify-content: center;

  @media screen and (max-width: 480px) {
    width: 300px;
  }

  .form-title {
    font-size: 20px;
    text-align: center;
    margin-bottom: 20px;

    &.form-title--post-payment {
      margin-bottom: 5px;
    }
  }

  .form-text-field {
    label {
      display: block;
      padding-top: 5px;
      padding-bottom: 5px;
    }

    a {
      padding-top: 5px;
      padding-bottom: 5px;
    }

    .pull-left {
      display: inline;
      float: left;
    }

    .pull-right {
      display: inline;
      float: right;
    }

    input {
      display: block;
      width: 100%;
      padding-top: 10px;
      padding-bottom: 10px;
      padding-left: 10px;
      border-top: none;
      border-left: none;
      border-right: none;
      border-bottom: 1px solid #000000;
      background-color: transparent;

      margin-bottom: 10px;
      &.input-error {
        margin-bottom: 0;
      }

      &[disabled] {
        &,
        &:hover,
        &:focus {
          color: #666;
          border-color: #ddd;
          background: #ddd;
        }
      }
    }

    *:focus {
      outline: none;
    }

    input[type="checkbox"] {
      display: inline;
      width: auto;
    }

    input[type="checkbox"] + label {
      display: inline;
      width: auto;
      padding-left: 10px;
    }

    input.input-error {
      border-bottom: 1px solid #ff0000;
    }
    span {
      display: block;
      height: 30px;
      padding-top: 5px;
      color: #ff0000;
    }

    &.form-text-field--show-password {
      span {
        display: flex;
        padding-top: 0;
        background-color: #f1f5f9;
        align-items: center;
        height: inherit;
      }

      input {
        margin-top: 7px;
        margin-bottom: 5px;
      }

      svg {
        margin-top: 4px;
      }
    }
  }

  .form-button {
    margin-top: 20px;
    display: block;
    background-color: #1cbcbc;
    padding: 10px;
    text-align: center;
    height: 48px;
    color: #ffffff;
    border: none;
    border-radius: 24px;
    width: 100%;
    cursor: pointer;
  }
}
