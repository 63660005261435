.intro-container {
  width: 100%;
  height: 100vh;
  min-height: 770px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &.custom-scrollbar {
    overflow-y: scroll;
    height: 100%;
    min-height: inherit;

    // firefox
    scrollbar-color: #d0d0d0 #eaeaea;

    // chrome, safari, edge, etc.
    &::-webkit-scrollbar {
      width: 12px;
      background-color: #eaeaea;
    }

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
      background-color: #eaeaea;
    }

    &::-webkit-scrollbar-thumb {
      -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
      background-color: #d0d0d0;
    }
  }

  @media screen and (max-width: 480px) {
    padding: 20px 0;
  }

  .language-selector {
    position: relative;
    margin-top: 80px;

    @media screen and (max-width: 480px) {
      margin-top: 20px;
    }

    label {
      color: black;
      margin-right: 24px;
      font-size: 16px;
      font-weight: 600;
    }
  }

  .intro-background {
    position: absolute;
    background: #f4f4f4 url(../../images/top_image@2x.png) center/cover
      no-repeat;
    width: 100%;
    height: 100%;
  }
  .blur-effect-on {
    /* Add the blur effect */
    filter: blur(30px);
    -webkit-filter: blur(30px);
  }
  .background-no-image {
    background: #fafafa;
  }

  .center-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1;
    max-width: 871px;
    .intro-description {
      font-size: 30px;
      text-align: center;
    }
    .intro-travelDx-logo {
      width: 55%;
    }
    .intro-youConnect-logo {
      width: 90%;
    }
    .logo-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 291px;
    }
  }
}

.logo-link {
  display: flex;
  justify-content: center;
}
.logo-top-center {
  width: 200px;
  position: absolute;
  top: 3%;
  margin: 0 auto;
}
.typo-content {
  z-index: 10;
  padding: 0% 5%;
  position: absolute;
  top: 15%;
  width: 100%;
  &.typo-content--text-only {
    top: 0%;
  }

  ul.typo-content__list-style-party {
    > li {
      list-style: none;
      margin: 10px 0;
    }
  }
}
.page-title {
  text-align: center;
}
.intro-link {
  height: 50px;
  padding: 10px 50px;
  border-radius: 30px;
  background-color: #1cbcbc;
  border: none;
  font-size: 24px;
  display: flex;
  align-items: center;

  &,
  &:hover {
    color: #ffffff;
  }
}
.intro-button-small {
  height: 40px;
  padding: 5px auto;
  border-radius: 25px;
  color: #ffffff;
  background-color: #1cbcbc;
  border: none;
  font-size: 15px;
  width: 100%;
}
.front-footer {
  position: absolute;
  bottom: 0;
  background: rgba(255, 255, 255, 0.6);
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
}
.txt-center {
  justify-content: center;
  text-align: center;
}

.container-fluid {
  clear: both;
  width: 100%;
  margin: 0 auto;
  padding: 0;
  background: #ffffff;

  .row {
    margin: 0;
    padding: 0;
  }
}

.container-fluid01 {
  clear: both;
  width: 100%;
  margin: 0 auto 60px auto;
  padding: 40px 0 0 0;
  background: #e2ebf0;
}

.container-fluid02 {
  clear: both;
  width: 100%;
  margin: 0 auto 40px auto;
  padding: 40px 0 0 0;
}

.header-box {
  width: 100%;
  margin: 0 auto;
  padding: 5px 0 5px 0;
  background: #1cbcbc;
  text-align: left;

  h1 {
    margin: 0;
    padding: 0;
    color: #ffffff;
    font-size: 14px;
    letter-spacing: 2px;
    font-family: 'Noto Sans JP', sans-serif;
    line-height: 1.1;
  }
}

.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;

  @media screen and (min-width: 768px) {
    width: 750px;
  }

  @media screen and (min-width: 992px) {
    width: 970px;
  }

  @media screen and (min-width: 1200px) {
    width: 1170px;
  }
}

.row {
  margin-left: -15px;
  margin-right: -15px;
}

img.title {
  margin: 10px 0;
  padding: 0;
  text-align: left;
}

.vis {
  width: 100%;
  margin: 0 auto;
  padding: 0;
  text-align: center;
  background: url(../../images/main_vis_bg.png) no-repeat;

  @media only screen and (max-width: 720px) {
    background: transparent;
  }
}

.md-img_switch {
  max-width: 100%;
}

.contact-box01 {
  width: 100%;
  margin: 60px auto 60px auto;
  padding: 0;
  text-align: center;

  @media only screen and (max-width: 720px) {
    margin: 30px auto 30px auto;
  }
}

.contact-box02 {
  width: 100%;
  margin: 0px auto 40px auto;
  padding: 0;
  text-align: center;

  @media only screen and (max-width: 720px) {
    margin: 0 auto 30px auto;
  }
}

.center-block {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

.img-responsive {
  max-width: 100%;
  height: auto;
}

.img03 {
  margin: 0 auto 30px auto;

  @media only screen and (max-width: 720px) {
    margin: 0 auto 20px auto;
  }
}

p.txt01 {
  margin: 0 auto 30px auto;
  font-size: 18px;
  line-height: 1.6em;
  text-align: justify;
  font-family: 'Noto Sans JP', sans-serif;

  @media only screen and (max-width: 767px) {
    margin: 0 auto 20px auto;
    font-size: 12px;
    text-align: left;
  }
}

p.txt02 {
  margin: 0 auto 0 auto;
  font-size: 18px;
  line-height: 1.6em;
  text-align: justify;
  font-family: 'Noto Sans JP', sans-serif;

  @media only screen and (max-width: 767px) {
    margin: 20px auto 0 auto;
    font-size: 12px;
    text-align: left;
  }
}

.contentsBox01 {
  width: 100%;
  margin: 0 0 40px 0;
  padding: 0;
  color: #000000;
  text-align: center;

  h2 {
    margin: 0 auto 40px auto;
    padding: 0;
    color: #222324;
    font-size: 36px;
    font-weight: 700;
    text-align: center;
    font-family: 'Noto Sans JP', sans-serif;
    line-height: 1.1;
  }

  .left-content {
    flex: 2;
    padding-right: 15px;
    padding-left: 15px;
  }

  .right-content {
    flex: 1;
    padding-right: 15px;
    padding-left: 15px;
  }

  .contents-intro {
    width: 100%;
    margin: 0 auto;
    padding: 0 20px;

    .listbox01 {
      width: 100%;
      height: auto;
      margin: 0 auto 20px auto;
      padding: 10px 15px;
      background: #62bcbc;
      border-radius: 5px;
      color: #ffffff;
      font-size: 24px;
      font-weight: 700;
      text-align: left;
      font-family: 'Noto Sans JP', sans-serif;
    }

    .listbox01end {
      width: 100%;
      height: auto;
      margin: 0 auto 0 auto;
      padding: 10px 15px;
      background: #62bcbc;
      border-radius: 5px;
      color: #ffffff;
      font-size: 24px;
      font-weight: 700;
      text-align: left;
      font-family: 'Noto Sans JP', sans-serif;
    }

    @media only screen and (max-width: 767px) {
      .listbox01 {
        padding: 10px;
        font-size: 12px;
      }

      .listbox01end {
        padding: 10px;
        font-size: 12px;
        margin: 0 auto 20px auto;
      }
    }
  }

  @media only screen and (max-width: 767px) {
    margin: 0 auto 20px auto;

    h2 {
      margin: 0 auto 15px auto;
      padding: 10px 0 10px 0;
      color: #000000;
      font-size: 18px;
      font-weight: bold;
    }
  }

  @media (min-width: 768px) {
    .row {
      display: flex;
    }
  }
}

.contentsBox02 {
  width: 100%;
  margin: 0;
  padding-bottom: 40px;
  color: #000000;
  text-align: center;

  .left-content {
    flex: 1;
    padding-right: 15px;
    padding-left: 15px;
  }

  .right-content {
    flex: 2;
    padding-right: 15px;
    padding-left: 15px;
  }

  @media only screen and (max-width: 767px) {
    margin: 0 auto;
    padding-bottom: 20px;
  }

  @media only screen and (min-width: 1200px) {
    .row {
      display: flex;
    }
  }
}

.contentsBox03 {
  width: 100%;
  margin: 0;
  padding-bottom: 40px;
  color: #000000;
  text-align: center;
  font-family: 'Noto Sans JP', sans-serif;

  h2 {
    margin: 0 auto 40px auto;
    padding: 0;
    color: #222324;
    font-size: 36px;
    font-weight: 700;
    text-align: center;
  }

  @media only screen and (max-width: 767px) {
    margin: 0 auto;
    padding-bottom: 20px;

    h2 {
      margin: 0 auto 15px auto;
      padding: 10px 0 10px 0;
      color: #000000;
      font-size: 18px;
      font-weight: bold;
    }
  }
}

.contents-benefit {
  clear: both;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;

  h3 {
    margin: 0 0 30px 0;
    padding: 10px 15px;
    background: #62bcbc;
    border-radius: 5px;
    color: #ffffff;
    font-size: 24px;
    font-weight: 500;
    font-family: 'Noto Sans JP', sans-serif;
    line-height: 1.1;

    @media only screen and (max-width: 767px) {
      margin: 0 0 20px 0;
      font-size: 12px;
    }
  }
}

dl.faq {
  clear: both;
  width: 100%;
  margin: 0 0 20px 0;
  padding: 0 0 10px 0;
  border-bottom: 1px dotted #ababab;
  font-family: 'Noto Sans JP', sans-serif;

  &.end {
    border-bottom: none;
  }

  dt {
    clear: left;
    float: left;
    width: 40px;
    margin: 0 0 0 0;
    padding: 0;
    font-size: 18px;
    font-weight: normal;
    line-height: 1.6em;
    vertical-align: middle;
    text-align: left;
  }

  dd.type01 {
    margin: 0 0 18px 0;
    padding: 8px 0 0 0;
    margin-left: 50px;
    font-size: 18px;
    line-height: 1.6em;
    text-align: left;
  }

  dd.type02 {
    margin: 0 0 0 0;
    padding: 8px 0 0 0;
    margin-left: 50px;

    p {
      margin: 0 0 30px 0;
      padding: 0;
      font-size: 18px;
      line-height: 1.6em;
      text-align: left;
    }
  }

  @media only screen and (max-width: 767px) {
    margin: 0 0 15px 0;

    dt {
      margin: 0 0 15px 0;
      font-size: 12px;
    }

    dd.type01 {
      font-size: 12px;
    }

    dd.type02 {
      padding: 10px 0 0 0;

      p {
        margin: 0;
        font-size: 12px;
      }
    }
  }
}

.company-box {
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
  font-family: 'Noto Sans JP', sans-serif;

  dl {
    width: 100%;
    margin: 0 auto;
    padding: 0;
    border-bottom: 1px dotted #ababab;

    dt {
      clear: left;
      float: left;
      width: 20%;
      margin: 0;
      padding: 10px 0 10px 0;
      font-size: 16px;
      text-align: left;
      font-weight: 700;
    }

    dd {
      margin-left: 7em;
      padding: 10px 0 10px 0;
      font-size: 16px;
      text-align: left;
    }
  }

  @media only screen and (max-width: 767px) {
    dl {
      dt {
        font-size: 12px;
      }

      dd {
        font-size: 12px;
      }
    }
  }
}

.footerBox {
  width: 100%;
  height: 40px;
  margin: 0 auto;
  padding: 0;
  background: #1cbcbc;

  .footer-box01 {
    width: 100%;
    margin: 0 auto;
    padding: 10px 0 0 0;
    text-align: center;
    color: #ffffff;
    font-family: 'Noto Sans JP', sans-serif;

    @media only screen and (max-width: 720px) {
      padding: 15px 0 0 0;
      font-size: 10px;
    }
  }
}

.return {
  text-align: right;
  padding-bottom: 30px;

  @media only screen and (max-width: 720px) {
    padding-top: 30px;
  }
}

#mailformpro {
  width: 80%;
  margin: 0 auto;
  padding: 10px 0px;

  dl {
    margin-top: 0;
    margin-bottom: 20px;

    dt {
      float: left;
      width: 280px;
      clear: both;
      margin: 0px;
      padding: 25px 10px 10px 0;
      text-align: right;
      border-top: solid 1px #CCC;
      font-size: 14px;
    }
    
    dd {
      border-top: solid 1px #CCC;
      margin: 0px;
      padding: 22px 5px 20px 280px;
      line-height: 1.5em;
      text-align: left;
      font-size: 14px;
    }
  }

  input, textarea {
    max-width: 100%;
    border: 2px groove;
    max-height: 6rem;

    &:disabled {
      border: 1px solid #eee;
      padding: 4px 12px;
      border-radius: 5px;
      color: #777;
      font-family: 'Noto Sans JP', sans-serif;
    }
  }

  .must {
    display: block;
    margin: 0 5px 5px 5px;
    padding: 2px 5px;
    background-size: 100% 100%;
    background-color: #C00;
    border: solid 2px #C00;
    text-shadow: 0px 1px 2px #933;
    font-size: 10px;
    color: #FFF;
    float: left;
    border-radius: 4px;
    box-shadow: 0px 0px 5px #ccc;
    line-height: 1.5em;

    &.marked {
      background-color: #1cbcbc;
      border: solid 2px #1cbcbc;
      text-shadow: none;
    }
  }

  .mfp_buttons {
    clear: both;
    padding: 10px 0px;
    text-align: center;
    background: none;
    border: none;

    .ant-btn {
      margin: 0 15px;
    }

    button[type="submit"] {
      background: none;
      border: none;
      cursor: pointer;
    }
  }

  @media only screen and (max-width: 767px) {
    width: 100%;
    .must {
      display: block;
      }

    dl {
      dt {
        width: 100%;
        padding: 10px 10px 10px 0;
        line-height: 2.0em;
        width: 70%;
        padding: 10px 10px 10px 0px;
        line-height: 2em;
        font-size: 12px;
        text-align: left;
      }

      dd {
        width: 100%;
        padding: 10px 5px 20px 0;
        font-size: 12px;
      }
    }
  }
}

.header-content {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 10px 0;

  .btn-content {
    margin-left: auto;
    padding: 10px 0;

    button {
      margin: 0 5px;
    }
  }
}
