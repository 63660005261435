@import "../../variables.scss";

.navbar-container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 24px 20px;
  margin-bottom: 30px;

  .nav-menu {
    display: flex;
    align-items: center;
    a {
      padding: 0 15px;
      color: #1d1d1d;
      @media screen and (max-width: 993px) {
        padding: 0 11px;
      }
      @media screen and (max-width: 925px) {
        padding: 0 9px;
      }
      @media screen and (max-width: 871px) {
        padding: 0 7px;
      }
      @media screen and (max-width: 843px) {
        padding: 0 5px;
      }
      @media screen and (max-width: 805px) {
        padding: 0 4px;
      }
    }

    .active {
      position: relative;

      &::after {
        content: "";
        width: 100%;
        position: absolute;
        border-bottom: 1px solid #15a0a0;
        bottom: -6px;
        left: 0;
        right: 0;
        margin: auto;
      }
    }
  }

  .nav-menu-underbar {
    border-bottom: 1px solid #1d1d1d;
  }

  .avatar {
    display: flex;
    width: 48px;
    height: 48px;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin: 0 0 0 15px;
    cursor: pointer;
    @media screen and (max-width: 805px) {
      margin: 0 0 0 8px;
    }
  }

  .business-logo-text {
    font-size: 34px;
    margin-right: auto;
    padding-left: 10px;
    @media (max-width: 991px) {
      font-size: 20px;
    }
  }
}

.nav-logo {
  width: auto;
  display: flex;
  align-items: center;
  @media (max-width: 991px) {
    width: 210px;
  }
  @media (max-width: 785px) {
    width: 170px;
  }
  @media (max-width: 767px) {
    width: 140px;
  }
}

.navbar-container-device {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2% 4%;

  .nav-menu-device {
    display: flex;
    .nav-menu-item {
      margin-left: 14px;
      @media only screen and (max-width: 320px) {
        margin-left: 3px;
      }
      .ant-btn {
        @media only screen and (max-width: 420px) {
          font-size: 12px;
          height: 27px;
          padding: 4px 8px;
        }
        @media only screen and (max-width: 320px) {
          font-size: 10px;
        }
      }
    }
    .menu-item {
      display: flex;
      align-items: center;
      margin-left: 14px;
      .ant-select {
        font-size: 12px;
        font-weight: 650;
        .ant-select-selector {
          padding-left: 3px;
          padding-right: 0;
          height: 20px;
          align-items: center;
        }
        .ant-select-arrow {
          padding-left: 7px;
          font-size: 9px;
          top: 56%;
        }
      }
      &:nth-child(2) {
        margin-right: 5px;
      }
      &:last-of-type {
        cursor: pointer;
      }
    }

    .nav-logo {
      margin: 0;
    }
    .menu-icon {
      font-size: 25px;
    }
  }
}
.nav-menu-item {
  .language-selector {
    display: flex;
    align-items: center;
    padding-left: 12px;
    @media screen and (max-width: 809px) {
      padding-left: 8px;
    }
  }

  .icon-notification {
    display: flex;
    align-items: center;
    padding: 0 15px;
    cursor: pointer;
    @media screen and (max-width: 823px) {
      padding: 0 6px;
    }
    svg {
      width: 20px;
      height: 20px;
    }
  }
}

.point-box-link {
  padding: 0 !important;
  display: flex;
  justify-content: flex-end;
  margin-top: 12px;
}
.point-box {
  min-width: 150px;
  height: 40px;
  padding: 0 12px;
  background-color: #1cbcbc;
  color: white;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .point-icon-container {
    display: flex;
    align-items: center;
  }
  .point {
    display: flex;
    .point-value {
      font-size: 18px;
      font-weight: 700;
    }
    .point-pts {
      font-size: 13px;
      display: flex;
      align-items: flex-end;
      padding-bottom: 3px;
      margin-left: 2px;
    }
  }
  .point-icon {
    margin-right: 5px;
    width: 12px;
    height: 18px;
  }
  b {
    font-size: 13px;
    margin-right: 3px;
  }
  p {
    margin: 0;
    font-size: 10px;
  }
}

.drawer-container {
  font-size: 20px;
  .point-box {
    margin: 0;
    min-width: 76px;
    width: fit-content;
    height: 28px;
    margin-bottom: 25px;
    .point-icon-container {
      display: flex;
      align-items: center;
    }
    .point {
      display: flex;
      .point-value {
        font-weight: 700;
      }
      .point-pts {
        font-size: 15px;
        display: flex;
        align-items: flex-end;
        padding-bottom: 3px;
        margin-left: 2px;
      }
    }
  }
  .nav-menu-item {
    margin-bottom: 25px;
    a {
      color: black;
      &:focus {
        color: #15a0a0;
      }
    }
  }
}

.no-auth-navbar {
  &.navbar-container {
    .nav-menu a {
      padding: 0 10px;
    }
  }

  .nav-menu-item {
    .language-selector {
      padding-left: 10px;
    }
  }
}

.site-title {
  font-size: 32px;
  margin: 0;

  @media screen and (max-width: 420px) {
    font-size: 24px;
  }
}