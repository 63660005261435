@import "~antd/dist/antd.css";
@import "~react-lazy-load-image-component/src/effects/blur.css";

@import "./variables.scss";

.top-header-banner {
  padding-top: 0;
  padding-bottom: 0;
  background-color: #1cbcbc;
  z-index: 1;

  @include breakpoint(xs) {
    padding: 4px 16px;
  }

  .ant-page-header-heading {
    justify-content: center;
  }
  .ant-page-header-heading-title {
    color: #fff;
    font-size: 16px;
    line-height: 28px;
    overflow: visible;
    white-space: initial;
    text-overflow: initial;

    @include breakpoint(xs) {
      font-size: 14px;
      line-height: 24px;
    }

    a,
    i {
      margin-left: 1px;
      margin-right: 1px;
      color: #fff;
      text-decoration: underline;
      font-style: italic;
    }
  }
}

.responsive-table {
  overflow-x: auto;
}

.main-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1024px;
  margin: auto;

  &.main-container--modern-design {
    max-width: 1600px;
    padding: 0 15px;

    .side-bar {
      padding: 0 20px;
    }

    .navbar-container {
      padding-bottom: 0;
    }

    @include breakpoint(lg) {
      &,
      .side-bar {
        padding: 0;
      }
    }
  }
}

table {
  tr {
    background-color: #fff;
  }
}

a,
a.ant-typography,
.ant-typography a {
  &.link-primary {
    color: #1cbcbc;

    &:hover,
    &:focus {
      color: #15a0a0;
    }
  }
}

.ant-btn-primary {
  background: #1cbcbc;
  border-color: #1cbcbc;

  &:hover,
  &:focus {
    background: #15a0a0;
    border-color: #15a0a0;
  }

  &:disabled {
    background: #ccc;
    border-color: #ccc;
    color: #fff;
  }

  &.ant-btn-background-ghost {
    border-color: #1cbcbc;
    color: #1cbcbc;

    &:hover {
      border-color: #15a0a0;
      color: #15a0a0;
    }
  }
}

input.single-border-input {
  background-color: transparent !important;
  border: none;
  border-bottom: 1px solid #707070;
}

.ant-steps-dot .ant-steps-item-icon,
.ant-steps-dot .ant-steps-item-process .ant-steps-item-icon {
  width: 15px;
  height: 15px;
}

.ant-steps-dot .ant-steps-item-tail {
  top: 6px;
}

.ant-steps-item-wait
  .ant-steps-item-icon
  > .ant-steps-icon
  .ant-steps-icon-dot {
  background: #cccccc;
}

.ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-tail::after,
.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: #cccccc;
}

.ant-steps-dot .ant-steps-item-tail::after {
  width: calc(100% - 15px);
}

.ant-menu-submenu-popup {
  display: none;
}

.ant-menu-sub.ant-menu-inline {
  .ant-menu-item {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .ant-menu-item-group-title {
    padding-left: 35px;
  }
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.credit-card-info {
  display: flex;
  justify-content: space-between;
  background: #f8f8f8;
  border: 1px solid #15a0a0;
  border-radius: 5px;
  padding: 15px;

  label {
    display: block;
    margin-bottom: 5px;
  }

  span {
    display: block;
  }

  .card-number span {
    font-size: 18px;
    font-weight: 600;
  }
}

.terms-of-use-title {
  font-size: 12px;
  margin-bottom: 5px;
  font-weight: bold;
}

.iframe-container {
  margin: 0 0 20px;
  border: 1px solid #c4c4c4;
  overflow-y: scroll;

  iframe {
    margin-bottom: 0; // fallback
    margin-bottom: -6px;
  }
}

.jst-time-content {
  color: #ff8800;
  font-weight: 500;
}

.daylight-note {
  color: #ff8800;
  font-style: italic;
  font-weight: 500;
}

.process-information-container {
  position: relative;
  padding: 16px;

  .spinner-container {
    padding-top: 40px;

    @include breakpoint(md) {
      display: none;
    }
  }
}

.search-input-container {
  width: 210px;

  @include breakpoint(lg) {
    width: calc(100vw - 30px);
  }
}

.other-search-inputs-container {
  display: flex;
}

.date-picker-container {
  margin-right: 10px;
}

.date-range-picker-container {
  .ant-picker-input {
    > input {
      width: 85px;

      @include breakpoint(md) {
        width: 100%;
      }
    }
  }

  .ant-picker-range-separator {
    padding-left: 0;

    @include breakpoint(md) {
      padding-left: 8px;
    }
  }
}

.time-picker-container {
  .ant-picker-input {
    > input {
      width: 70px;

      @include breakpoint(md) {
        width: 100%;
      }
    }

    .ant-picker-range-separator {
      padding-left: 0;

      @include breakpoint(md) {
        padding-left: 8px;
      }
    }
  }
}

.checked-items-checkbox-container {
  .show-itineraries-checkbox,
  .popular-checkbox {
    margin-right: 5px;
  }
}

/* Helper styles */
.cursor-pointer {
  cursor: pointer;
}

.display-none {
  display: none;
}

.width-fit-content {
  width: auto; // fallback
  width: fit-content;
}

.travel-category-label {
  height: 24px;
  padding: 0 12px;
  border-radius: 24px;
  background-color: #a29f9f;
  color: #fff;
  text-align: center;
  font-size: 12px;
  line-height: 2;
  margin-right: 2px;
  margin-bottom: 2px;

  &.is-1 {
    background-color: #44d692;
  }

  &.is-2 {
    background-color: #ebb2a8;
  }

  &.is-3 {
    background-color: #98d6e4;
  }

  &.is-4 {
    background-color: #fcac48;
  }

  &.is-5 {
    background-color: #b89bfe;
  }

  &.is-6 {
    background-color: #2ca2ba;
  }
}

.notice-description {
  max-height: 400px;
  overflow-y: auto;
  white-space: pre-wrap;
  margin: 0;
}

.custom-intro-tooltip {
  width: 80vw !important;
  max-width: 400px !important;

  .introjs-skipbutton {
    display: none;
  }
}

.intro-flashing {
  position: absolute;
  border: 2px solid orange;
  top: -5px;
  left: -5px;
  right: -5px;
  bottom: -5px;
  border-radius: 50%;
}

/* Google Maps Styling overrides */
.gm-style {
  .marker-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__name {
      margin-bottom: 8px;
      font-weight: bold;
    }

    p {
      margin-bottom: 8px;
    }

    &__action {
      text-decoration: underline;
    }
  }
}
