footer {
  margin-top: 90px;
}

.footer-content {
  background-color: #464655;
  padding: 60px 15px;

  .main-container {
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
  }

  .footer-links {
    display: flex;
    justify-content: space-evenly;
    flex: 1;
    min-width: 280px;
    margin-top: 10px;

    h4 {
      color: white;
      opacity: 0.6;
      font-weight: 400;
      padding: 6px 0;
    }

    a {
      display: block;
      color: white;
      padding: 6px 0;
    }
  }

  .extra-items {
    margin-top: 10px;
    .language-container {
      margin-bottom: 5px;
      color: white;
      opacity: 0.6;
    }
    .language {
      margin: 0 0 10px 3px;
    }
  }

  .auth-buttons {
    margin-top: 25px;

    button {
      & + button {
        margin-left: 15px;
      }

      @media screen and (max-width: 360px) {
        height: 32px;
        padding: 4px 16px;
        font-size: 14px;
        border-radius: 32px;

        & + button {
          margin-left: 8px;
        }
      }
    }
  }
}

.copyright-content {
  background-color: #32323f;
  padding: 15px;

  .main-container {
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
  }

  .footer-links {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding-right: 10px;

    a {
      color: white;
      opacity: 0.6;
      padding: 0 5px;
      text-align: center;
      line-height: 0.6;
      margin: 4px 0;

      & + a {
        border-left: 1px solid white;
      }

      @media screen and (max-width: 593px) {
        &:last-child {
          border-color: transparent;
          line-height: 1.5;
        }
      }
    }
  }

  p {
    padding-left: 15px;
    color: white;
    opacity: 0.6;
    margin: 4px 0;
    line-height: 0.6;
    border-left: 1px solid white;

    @media screen and (max-width: 847px) {
      border-color: transparent;
      line-height: 1.5;
    }
  }
}
